import axios from 'axios';
// post json to api
export const postJson = (url, params) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            resolve(response.data);
        }
        ).catch(error => {
            reject(error);
        }
        );
    });
};
