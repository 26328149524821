import React from "react";
import { Button, Card, Container } from "react-bootstrap";

import {
    EGGX_CONTRACT,
    EGGX_CONTRACT_V2,
} from "../../common/constant";


import { MaxUint256, ethers } from "ethers";
import { ABI_ERC20, connect, watchTxRecp } from "../../common/dapp";

// const CONTRACT_SWAP = "0xf55fb0EE2773D513E6843580C3266Bd38CFfbd67";
// const CONTRACT_SWAP = '0x5aDf16034a986eBc95DBA65Cc2219737C7c12876';
const CONTRACT_SWAP = '0x98Dc27B72145CA921e8361c23514afcaaE073cab';
const ABI_SWAP = [
    'function swap() external',
]

class _SwapPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            oldBalance: 'Loading...',
            newBalance: 'Loading...',
            needApprove: true,
            loading: false,
            timer: null
            // value: 0,
            // transactionHash: '',
        };
    }

    async componentDidMount() {
        this.loadBalance();
    }

    approve = async () => {
        const options = await connect();
        if (options === null) {
            return;
        }

        const provider = options.provider;
        const signer = await provider.getSigner();
        const address = options.address;

        // loading true
        this.setState({ loading: true });

        // balance
        const balance0 = await new ethers.Contract(EGGX_CONTRACT, ABI_ERC20, provider).balanceOf(address);

        const token0 = new ethers.Contract(EGGX_CONTRACT, ABI_ERC20, signer);
        try {
            const tx = await token0.approve(CONTRACT_SWAP, balance0);
            console.log(tx);

            watchTxRecp(this.state.timer, {
                txid: tx.hash,
                provider: options.provider,
                success: (receipt) => {
                    console.log(receipt);
                    this.loadBalance();
                    this.setState({ loading: false });

                    this.loadBalance();
                },
                failed: (receipt) => {
                    console.log(receipt);
                    this.setState({ loading: false });
                }
            });
        } catch (e) {
            alert(e)
            this.setState({ loading: false });
        }
        // this.setState({ transactionHash: tx.hash });
    }

    swap = async () => {
        const options = await connect();
        if (options === null) {
            return;
        }
        // loading true
        this.setState({ loading: true });

        const provider = options.provider;
        const signer = await provider.getSigner();
        try {
            const contract = new ethers.Contract(CONTRACT_SWAP, ABI_SWAP, signer);
            const tx = await contract.swap();
            console.log(tx);
            // this.setState({ transactionHash: tx.hash });
            watchTxRecp(this.state.timer, {
                txid: tx.hash,
                provider: options.provider,
                success: (receipt) => {
                    console.log(receipt);
                    this.loadBalance();
                    this.setState({ loading: false });

                    this.loadBalance();
                },
                failed: (receipt) => {
                    console.log(receipt);
                    this.setState({ loading: false });
                }
            });
        } catch (e) {
            alert(e);
            this.setState({ loading: false });
        }
    }

    loadBalance = async () => {
        const options = await connect();
        if (options === null) {
            return;
        }

        const provider = options.provider;
        const address = options.address;

        const token0 = new ethers.Contract(EGGX_CONTRACT, ABI_ERC20, provider);
        const balance0 = await token0.balanceOf(address);
        const allowance = await token0.allowance(address, CONTRACT_SWAP);

        const token1 = new ethers.Contract(EGGX_CONTRACT_V2, ABI_ERC20, provider);
        const balance1 = await token1.balanceOf(address);


        this.setState({
            oldBalance: ethers.formatUnits(balance0, 18).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' EGGX',
            newBalance: ethers.formatUnits(balance1, 18).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' EGGX',
            // needApprove: allowance < balance0,
            needApprove: allowance < balance0,
        });
    }

    render() {
        return (
            <div style={{
                height: "100%",
                minHeight: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

            }}>
                <div style={{
                    maxWidth: "600px",
                    width: "100%",
                }}>
                    <div style={{
                        margin: "1rem",
                    }}>
                        <h3>EGGX SWAP</h3>
                    </div>
                    <div style={{ height: '1rem' }}></div>
                    <Container>
                        <Card>
                            <Card.Body>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}>
                                    <div>OLD Balance: </div>
                                    <div>{this.state.oldBalance} </div>
                                </div>
                                <div>
                                    OLD Contract: {EGGX_CONTRACT}
                                </div>
                            </Card.Body>
                        </Card>
                        <div style={{ height: '1rem' }}></div>
                        <div className="d-grid gap-2">
                            {/* 背景是从#86EFEF到#A9FB7D过渡色 */}
                            <Button style={{
                                backgroundImage: "linear-gradient(45deg, #86EFEF, #A9FB7D)",
                                color: "black",
                                border: "none",
                                height: "3.6rem",
                            }} variant="primary" size="lg" onClick={(e) => {
                                if (this.state.needApprove) {
                                    this.approve();
                                } else {
                                    this.swap();
                                }
                            }}>
                                {/* {t('CheckButton')} */}
                                {!this.state.needApprove ? 'Swap' : 'Approve'}
                            </Button>
                        </div>

                        <div style={{ height: '1rem' }}></div>
                        <Card>
                            <Card.Body>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}>
                                    <div>NEW Balance: </div>
                                    <div>{this.state.newBalance} </div>
                                </div>
                                <div>
                                    New Contract:  {EGGX_CONTRACT_V2}
                                </div>

                            </Card.Body>
                        </Card>
                    </Container>
                </div>
                {/* loading mask */}
                <div style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: this.state.loading ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "999",
                }}>
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                    }}>
                        {/* <img src={require('../../assets/icons/loading.gif')} style={{
                        width: "4rem",
                        height: "4rem",
                    }} /> */}

                        Loading...
                    </div>
                </div>
            </div>
        );
    }
}

function SwapPage() {
    return <_SwapPage />;
}

export default SwapPage;